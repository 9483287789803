<template>
  <div class="d-flex" style="position: relative">
    <div class="edit-cell d-flex align-center"
         :class="{ 'table-preview' : previewRegions }"
         v-for="(column, index) in columns" :key="index"
         :style="{width: column.width + 'in'}"
    >
      {{ displayContent(column) }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'AgendaStyleRegionColumn',
  props: {
    row: {},
    columns: {}
  },
  computed: {
    ...mapState('region', ['previewRegions', 'selectedRegion'])
  },
  methods: {
    displayContent (column) {
      if (column.parsedContent !== undefined && column.parsedContent.length > 0) return column.parsedContent
      return column.content
    }
  }
})
</script>
