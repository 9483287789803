<template>

  <v-dialog
    width="750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Meeting Variables of <span class="font-weight-black">{{ agendaStyle.name }}</span> style</span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col>

            <v-form ref="meetingVariablesForm" lazy-validation @submit.prevent="">
              <v-text-field
                outlined
                label="New meeting variable"
                clearable
                v-model="meetingVariablesValue"
                @focus="addMeetingVariablesFocus=true"
                @blur="addMeetingVariablesFocus=false;resetForm($refs.meetingVariablesForm)"
                :rules="rules.variableLabelRules"
                :error-messages="customErrorMessage"
              >
                <template v-slot:append>
                  <v-icon
                    v-if="!addMeetingVariablesFocus"
                    color="lightGrey"
                  >
                    mdi-plus-circle
                  </v-icon>
                  <v-icon
                    v-else
                    color="secondary"
                    @click="addMeetingVariables({label: meetingVariablesValue})"
                  >
                    mdi-plus-circle
                  </v-icon>
                </template>
              </v-text-field>
            </v-form>

            <v-container v-if="variablesLabels.length === 0">
              <v-alert
                dense
                text
                class="text-left"
                type="warning"
                :icon=false
              >
                This Agenda Style does not have any Meeting Variables yet.
              </v-alert>
            </v-container>
            <v-container>
              <draggable
                v-model="variablesLabels"
                @start="startingDrag()"
                @end="endingDrag()"
                v-bind="dragOptions">
                <v-row
                  v-for="(item,n) in variablesLabels"
                  :key="n"
                  class="draggableRow"
                >
                  <v-col width="10%">
                    <v-icon color="lightGrey" class="handle">
                      mdi-drag-horizontal-variant
                    </v-icon>
                  </v-col>
                  <v-col>{{ item.name }}</v-col>
                  <v-col align="right" width="150">
                      <span class="d-flex justify-end">

                        <v-menu
                          offset-y
                          top
                          left
                          open-on-click
                          :close-on-content-click="false"
                          v-if="!item.active"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="darkGrey"
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon size="18"
                                @click="setMeetingVariableInputValue(item)">
                                mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <v-list width="400">
                            <v-list-item>
                              <v-text-field
                                v-model="meetingVariableInputValue"
                                :rules="rules.variableLabelRules">
                                <template v-slot:append>
                                  <v-btn
                                    color="darkGrey"
                                    icon
                                    @click="saveMeetingVariableInputValue(item)"
                                  >
                                    <v-icon
                                      color="secondary"
                                    >
                                      mdi-send
                                    </v-icon>
                                  </v-btn>
                                </template>
                              </v-text-field>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <v-speed-dial
                          direction="left"
                          v-model="item.active"
                          v-if="meetingVariablesDelete"
                        >
                          <template v-slot:activator>
                            <v-btn
                              icon
                              v-model="item.active"
                            >
                              <v-icon color="important" size="18">
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <v-btn
                            fab
                            dark
                            x-small
                            color="grey"
                          >
                            NO
                          </v-btn>
                          <v-btn
                            fab
                            dark
                            x-small
                            color="important"
                            @click="deleteMeetingVariables(n)"
                          >
                            YES
                          </v-btn>
                        </v-speed-dial>

                      </span>
                  </v-col>
                </v-row>
              </draggable>
            </v-container>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Close</v-btn>
        <v-btn v-if="settingsDirty" depressed width="130" @click="saveSettings()" class="green white--text">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          Save Settings
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import draggable from 'vuedraggable'

export default Vue.extend({
  name: 'AgendaStyleMeetingVariables',
  props: {
    agendaStyle: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  components: {
    draggable
  },
  data: () => ({
    addMeetingVariablesFocus: false,
    meetingVariablesDelete: true,
    meetingVariablesValue: '',
    loading: false,
    saving: false,
    variablesLabels: [
      /*
      { name: 'Zoom Meeting Link', active: false },
      { name: 'Zoom Meeting ID', active: false },
      { name: 'Zoom Meeting Number', active: false },
      { name: 'Pledge Leader Name', active: false },
      { name: 'Briefing Time', active: false }
      */
    ],
    meetingVariables: [],
    deletedVariables: [],
    rules: {
      variableLabelRules: [
        v => !!v || 'Variable name cannot be blank',
        v => (v && v.length <= 50) || 'Variable name must be less than 50 characters'
      ]
    },
    customErrorMessage: '',
    initialMeetingVariablesSettings: {},
    settingsDirty: false,
    meetingVariableInputValue: '',
    dragging: false
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    dragOptions () {
      return {
        handle: '.handle',
        animation: 500,
        ghostClass: 'ghostClass',
        dragClass: 'dragClass',
        chosenClass: 'chosenClass'
      }
    }
  },
  methods: {
    testFunction () {
      // console.log(1)
    },
    initialAddMeetingVariables (item) {
      this.variablesLabels.push({ name: item.label, active: false, position: item.position, id: item.id })
    },
    addMeetingVariables (item) {
      if (this.$refs.meetingVariablesForm.validate()) {
        if (this.isUniqueValue(item.label, this.variablesLabels, 'name')) {
          this.variablesLabels.push({
            name: item.label,
            active: false,
            position: item.position,
            id: null
          })
          this.meetingVariablesValue = ''
          this.clearCustomErrorMessage()
          this.resetForm(this.$refs.meetingVariablesForm)
        } else {
          this.customErrorMessage = 'You must enter a unique variable label'
        }
      }
    },
    isUniqueValue (value, array, key) {
      for (const currentValue in array) {
        if (value.toLowerCase() === array[currentValue][key].toLowerCase()) {
          return false
        }
      }
      return true
    },
    deleteMeetingVariables (item) {
      this.deletedVariables.push(this.variablesLabels[item].id)
      this.variablesLabels.splice(item, 1)
    },
    getAgendaStyleMeetingVariables () {
      this.loading = true
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-styles/meeting-variables/' + this.agendaStyle.id)
        .then((response) => {
          this.meetingVariables = response.data
          this.meetingVariables.forEach(variable => {
            this.initialAddMeetingVariables(variable)
            this.variablesLabels.sort(this.sortMeetingVariables)
          })
          this.initialMeetingVariablesSettings = JSON.stringify(this.variablesLabels)
          this.loading = false
        }).catch(function (error) {
          console.log(error)
        })
    },
    clearCustomErrorMessage () {
      this.customErrorMessage = ''
    },
    resetForm (form) {
      // triggered on field blur
      form.resetValidation()
      form.reset()
      this.clearCustomErrorMessage()
    },
    sortMeetingVariables (a, b) {
      if (a.position < b.position) {
        return -1
      }
      if (a.position > b.position) {
        return 1
      }
      return 0
    },
    compareToInitialSettings () {
      if (JSON.stringify(this.variablesLabels) === this.initialMeetingVariablesSettings) {
        this.settingsDirty = false
      } else {
        this.settingsDirty = true
      }
    },
    setMeetingVariableInputValue (item) {
      this.meetingVariableInputValue = item.name
    },
    saveMeetingVariableInputValue (item) {
      if (this.meetingVariableInputValue !== '') {
        item.name = this.meetingVariableInputValue
        this.compareToInitialSettings()
      }
    },
    startingDrag () {
      this.dragging = true
    },
    endingDrag () {
      this.dragging = false
    },
    saveSettings () {
      this.saving = true
      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/agenda-styles/save-meeting-variables/', { style_id: this.agendaStyle.id, variables: this.variablesLabels, deletedVariables: this.deletedVariables })
        .then((response) => {
          this.show = false
        }).catch(function (error) {
          console.log(error)
        })
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.settingsDirty = false
        this.meetingVariables = []
        this.variablesLabels = []
        this.deletedVariables = []
        this.saving = false
        this.getAgendaStyleMeetingVariables()
      } else {
      }
    },
    variablesLabels () {
      // Compare the the initial settings every time the object changes
      this.compareToInitialSettings()
    }
  }
})

</script>

<style>
.draggableRow {
  border-bottom:solid 1px lightgrey;
}
.draggableRow:first-child .col {
  border-top:solid 1px lightgrey;
}
.ghostClass {
  height: 60px;
  border: solid 2px rgb(38, 74, 98) !important
}
.chosenClass {
  background-color: rgb(230, 244, 253);
}
.dragClass {
  border: solid 2px rgb(124, 46, 46) !important
}
</style>
