<template>

  <v-dialog
    :width="modeType==='edit' || modeType==='duplicate' ?600:750"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>
          <span v-if="modeType=='create'">New Agenda Style</span>
          <span v-if="modeType=='edit'">Edit Agenda Style</span>
          <span v-if="modeType=='duplicate'">Duplicate Agenda Style</span>
          <span> for <span class="font-weight-black">{{ boardName }}</span></span>
        </span>
        <span v-if="modeType=='edit'||modeType=='duplicate'"><span class="font-weight-black">{{ agendaStyle.name }}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    outlined
                    label="Name"
                    v-model="aName"
                    :rules="rules.nameRules"
                    :counter="50"
                    class="text-caption"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    label="Description"
                    v-model="aDescription"
                    :rules="rules.descRules"
                    :counter="512"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="modeType==='create'">
                <v-col>
                  <v-select
                    :items="predefinedStyles"
                    label="Pre-defined Styles"
                    v-model="aPreDefinedStyle"
                    :rules="rules.preDefinedStylesRules"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-col >
          </v-row>
          <v-row>
            <v-col
              v-if="modeType==='create'"
              class="d-flex align-stretch justify-space-between"
            >

              <v-card
                elevation="6"
                class="mini-letter-preview"
              >
                <v-card-text class="pa-0" style=" font-size: 6pt; line-height: 9pt;">

                  <span class="letter-preview">
                    <v-chip class="ma-2">
                      Preview
                    </v-chip>
                  </span>

                  <span class="pb-6 d-flex flex-column">
                  <span>City of North Richland Hills</span>
                  <span>City Council</span>
                  <span>7:40 PM</span>
                  <span>the 1st day of June, 2020</span>
                  <span>4301 City Point Dr</span>

                  <span class="mt-6 d-flex flex-column">
                    <span>1. Call Meeting To Order</span>
                    <span>2. State Pledge of Allegiance</span>
                    <span>3. Pledge of Allegiance</span>
                    <span>
                      4. Consent Area
                      <span class="d-flex flex-column ml-6">
                        <span>A. Text sample</span>
                        <span>
                          B. Text sample bigger
                           <span class="d-flex flex-column ml-6">
                            <span>a. Text sample</span>
                            <span>
                              b. Text sample bigger
                               <span class="d-flex flex-column ml-6">
                                <span>I. Text sample</span>
                                <span>II. Text sample bigger</span>
                                <span>III. Text sample much bigger</span>
                                <span>
                                  IV. Tiny txt
                                  <span class="d-flex flex-column ml-6">
                                    <span>&bull; Text sample</span>
                                    <span>&bull; Text sample bigger</span>
                                    <span>&bull; Text sample much bigger</span>
                                    <span>&bull; Tiny txt</span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                        <span>C. Text sample much bigger</span>
                        <span>D. Tiny txt</span>
                      </span>
                    </span>
                    <span>
                      5. Item Sample
                      <span class="d-flex flex-column ml-6">
                        <span>A. Text much bigger than the other ones</span>
                        <span>B. Text sample</span>
                      </span>
                    </span>
                    <span>6. Pledge of Allegiance</span>
                  </span>
                </span>

                </v-card-text>
              </v-card>

              <v-card
                class="grey lighten-3 pl-2 pr-16"
                elevation="0"
                tile
              >
                <v-card-text>
                  <v-row>
                    <v-col class="d-flex flex-column">
                      <span class="text-uppercase font-weight-bold d-flex">
                        Font-Family
                      </span>
                      <span>Roboto</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex flex-column">
                      <span class="text-uppercase font-weight-bold d-flex">
                        Font-Size
                      </span>
                      <span>12pt</span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="text-uppercase font-weight-bold d-flex mb-2">
                        Numbering
                      </span>
                      <span>
                      <span class="d-flex mb-1">
                        <span class="mr-2">1.</span>Region
                      </span>
                      <span class="d-flex mb-1 ml-2">
                        <span class="mr-2">A.</span> Great
                      </span>
                      <span class="d-flex mb-1 ml-4">
                        <span class="mr-2">a.</span> Grant
                      </span>
                      <span class="d-flex mb-1 ml-6">
                        <span class="mr-2">I.</span> Parent
                      </span>
                      <span class="d-flex mb-1 ml-8">
                        <span class="mr-2">&bull;</span> First
                      </span>
                    </span>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <span class="text-uppercase font-weight-bold d-flex mb-2">
                        Margins
                      </span>
                      <span>0.5"</span>
                    </v-col>
                  </v-row>

                </v-card-text>
              </v-card>

            </v-col>
          </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Cancel</v-btn>
        <v-btn depressed width="130" color="secondary">
          <span v-if="modeType=='create'" @click="submit()">Add</span>
          <span v-if="modeType=='edit'" @click="submit()">Update</span>
          <span v-if="modeType=='duplicate'" @click="submit()">Duplicate</span>
        </v-btn>
      </v-card-actions>

    </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

function initialState () {
  return {
    valid: true,
    saving: false,
    form: {
      id: '',
      name: '',
      description: '',
      predefinedSelected: 0
    },
    tab: null,
    numberingType: [
      { type: 'Decimal', sample: '1.' },
      { type: 'Capital Alphabet', sample: 'A.' },
      { type: 'Lowercase Alphabet', sample: 'a.' },
      { type: 'Capital Roman', sample: 'I.' },
      { type: 'Lowercase Roman', sample: 'i.' },
      { type: 'Circle', sample: '●' },
      { type: 'Square', sample: '■' },
      { type: 'Disc', sample: '○' },
      { type: 'Diamond', sample: '◆' },
      { type: 'Same as previous region', sample: 'previous', exclude: true }
    ],
    numberingSelected: [
      { selected: '1.', symbol: '1.' },
      { selected: 'A.', symbol: 'A.' },
      { selected: 'a.', symbol: 'a.' },
      { selected: 'I.', symbol: 'I.' },
      { selected: '●', symbol: '●' }
    ],
    predefinedStyles: [
      'Standard', 'Regular', 'Complex', 'Annual Report', 'Roadmap'
    ],
    rules: {
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 50) || 'Name must be less than 50 characters'
      ],
      descRules: [
        v => !!v || 'Description is required',
        v => (v && v.length <= 512) || 'Description must be less than 512 characters'
      ],
      preDefinedStylesRules: [
        v => !!v || 'Style is required'
      ]
    }
  }
}

export default Vue.extend({
  name: 'AgendaStyleCreateUpdate',
  props: {
    agendaStyle: {},
    boardSelected: {},
    boardName: String,
    value: {
      required: true,
      type: Boolean
    },
    modeType: {
      required: true,
      type: String
    }
  },
  data: function () {
    return initialState()
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    aName: {
      get () {
        return this.agendaStyle.name
      },
      set (val) {
        this.form.name = val
      }
    },
    aDescription: {
      get () {
        return this.agendaStyle.description
      },
      set (val) {
        this.form.description = val
      }
    },
    aPreDefinedStyle: {
      get () {
        return this.agendaStyle.type
      },
      set (val) {
        this.form.predefinedSelected = val
      }
    },
    numberingFilter () {
      return this.numberingType.filter(item => item.exclude === undefined)
    }
  },
  methods: {
    submit () {
      const formPassedValidation = this.$refs.form.validate()
      if (formPassedValidation === false) {
        return false
      }

      this.saving = true

      const axiosAction = (this.modeType === 'create' || this.modeType === 'duplicate') ? 'post' : 'put'
      const axiosUrl = (this.modeType === 'edit') ? '/api/agenda-styles/' + this.agendaStyle.id : '/api/agenda-styles'

      // Temporary Setting Type
      this.form.type = 'Regular Meeting'
      this.form.board_id = this.boardSelected

      axios({
        method: axiosAction,
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data: this.form
      }).then(() => {
        this.closeModal()
        this.$emit('reload', true)
      }).catch(error => {
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
      })
    },
    closeModal () {
      this.$refs.form.reset()
      this.show = false
      this.saving = false
    },
    resetModal () {
      if (this.$refs.form) {
        this.$refs.form.reset()
      }
      this.form = {
        status: 'active'
      }
      this.saving = false
    }
  },
  watch: {
    numberingSelected: {
      handler () {
        for (let i = 0; i < 5; i++) {
          if (this.numberingSelected[i].selected === 'previous') {
            this.numberingSelected[i].symbol = this.numberingSelected[i - 1].symbol
          } else {
            this.numberingSelected[i].symbol = this.numberingSelected[i].selected
          }
        }
      },
      deep: true
    },
    show: function (value) {
      if (value) {
        Object.assign(this.$data, initialState())
      }
      this.form.client_id = this.$store.state.client.activeClient.clientID
      // if (this.modeType === 'create') {
      //   if (this.$refs.form) {
      //     this.$refs.form.reset()
      //   }
      //   this.aName = ''
      //   this.aDescription = ''
      // } else {
      //   this.aName = this.agendaStyle.name
      //   this.aDescription = this.agendaStyle.description
      // }

      if (value === true && this.modeType === 'edit') {
        this.form.name = this.agendaStyle.name
        this.form.description = this.agendaStyle.description
        this.form.client_id = this.$store.state.client.activeClient.clientID
      } else if (value === true && this.modeType === 'create') {
        this.resetModal()
      }
    }
  }
})
</script>
