<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-view-compact</v-icon>
          <h1 class="darkGrey--text">Agenda Styles</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Agenda Styles
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-btn
          depressed
          height="40"
          color="orange lighten-2"
          class="white--text"
          @click="openModal('createUpdate', 'create')"
          v-if="boardSelected!==''"
        >
          <v-icon size="18">mdi-plus</v-icon> Add Agenda Style
        </v-btn>

      </v-col>
    </v-row>

    <v-row class="my-6">
      <v-col>
        <v-divider class="custom-divider"></v-divider>
      </v-col>
    </v-row>

    <v-row class="d-flex mb-6">
      <v-col
        class="d-flex"
        cols="12"
        sm="6"
      >
        <v-select
          :key="Math.random()"
          label="Board"
          :clearable="true"
          :items="activeBoards"
          v-model="boardSelected"
          :item-value="item => item.id"
          :item-text="item => item.name"
          hide-details
        ></v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :loading="loading"
            :search="search"
            :items="agendaStyles"
            :server-items-length="agendaStyleTotalCount"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': [10, 20, 30]
            }"
          >
            <template v-slot:item.name="{ item }">
              <span class="py-6 d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <span class="text-caption">
                  {{ item.description }}
                </span>
              </span>
            </template>
            <template v-slot:item.status="{ item }">
              <v-tooltip top v-if="item.pending">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-timer-sand
                  </v-icon>
                </template>
                <span>
                  Pending
                </span>
              </v-tooltip>
              <v-tooltip top v-if="item.active">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="positive"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check-circle-outline
                  </v-icon>
                </template>
                <span>
                  Active
                </span>
              </v-tooltip>
              <v-tooltip top v-if="item.paused">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="important"
                    size="24"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pause
                  </v-icon>
                </template>
                <span>
                  Paused
                </span>
              </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-row align-center justify-end">

                <v-menu offset-y transition="slide-x-transition" v-if="item.draft">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                      small
                    >
                      Draft
                      <v-icon size="14">mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsDraft"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <v-tooltip top v-if="!item.draft">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal('regions', null, item)"
                    >
                      <v-icon size="20">
                        mdi-table-edit
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Regions
                  </span>
                </v-tooltip>

                <v-tooltip top v-if="!item.draft">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="darkGrey"
                      size="18"
                      v-bind="attrs"
                      v-on="on"
                      @click="openModal('pageSettings', null, item)"
                    >
                      <v-icon size="20">
                        mdi-file-cog-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Page settings
                  </span>
                </v-tooltip>

                <v-menu transition="slide-x-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group>
                      <v-list-item
                        v-for="(itemMenu, index) in itemsMenuFiltered(item)"
                        :key="index"
                        @click="openModal(itemMenu.actions, itemMenu.optional, item)"
                        dense
                      >
                        <v-list-item-icon>
                          <v-icon
                            :color="itemMenu.type? itemMenu.type:'darkGrey'"
                          >
                            {{ itemMenu.icon }}
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                          >
                            {{ itemMenu.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <agenda-style-create-update @reload="getDataFromApi" v-model="modals.createUpdate" :modeType="modeType" :boardSelected="boardSelected" :boardName="boardName" :agendaStyle="agendaStyle"></agenda-style-create-update>
    <agenda-style-edit @reload="getDataFromApi" v-model="modals.edit" :agendaStyle="agendaStyle"></agenda-style-edit>
    <agenda-style-regions v-model="modals.regions" :builderType="builderType" :agendaStyle="agendaStyle"></agenda-style-regions>
    <agenda-style-page-settings @reload="getDataFromApi" v-model="modals.pageSettings" :agendaStyle="agendaStyle"></agenda-style-page-settings>
    <agenda-style-vote-format @reload="getDataFromApi" v-model="modals.vote" :agendaStyle="agendaStyle" :boardName="boardName"></agenda-style-vote-format>
    <agenda-style-meeting-variables v-model="modals.variables" :agendaStyle="agendaStyle"></agenda-style-meeting-variables>
    <agenda-style-previous-versions v-model="modals.previous" :agendaStyle="agendaStyle"></agenda-style-previous-versions>
    <agenda-style-resume @reload="getDataFromApi" v-model="modals.resume" :agendaStyle="agendaStyle"></agenda-style-resume>
    <agenda-style-pause  @reload="getDataFromApi" v-model="modals.pause" :agendaStyle="agendaStyle"></agenda-style-pause>
    <agenda-style-activate @reload="getDataFromApi" v-model="modals.activate" :agendaStyle="agendaStyle"></agenda-style-activate>
    <agenda-style-delete @reload="getDataFromApi" v-model="modals.delete" :agendaStyle="agendaStyle"></agenda-style-delete>
    <agenda-style-draft-publish @reload="getDataFromApi" v-model="modals.draftPublish" :agendaStyle="agendaStyle"></agenda-style-draft-publish>
    <agenda-style-draft-delete @reload="getDataFromApi" v-model="modals.draftDelete" :agendaStyle="agendaStyle"></agenda-style-draft-delete>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import AgendaStyleCreateUpdate from '@/components/AgendaStyles/AgendaStyleCreateUpdate'
import AgendaStyleEdit from '@/components/AgendaStyles/AgendaStyleEdit'
import AgendaStyleRegions from '@/components/AgendaStyles/AgendaStyleRegions'
import AgendaStylePageSettings from '@/components/AgendaStyles/AgendaStylePageSettings'
import AgendaStyleVoteFormat from '@/components/AgendaStyles/AgendaStyleVoteFormat'
import AgendaStyleMeetingVariables from '@/components/AgendaStyles/AgendaStyleMeetingVariables'
import AgendaStylePreviousVersions from '@/components/AgendaStyles/AgendaStylePreviousVersions'
import AgendaStyleResume from '@/components/AgendaStyles/AgendaStyleResume'
import AgendaStylePause from '@/components/AgendaStyles/AgendaStylePause'
import AgendaStyleActivate from '@/components/AgendaStyles/AgendaStyleActivate'
import AgendaStyleDelete from '@/components/AgendaStyles/AgendaStyleDelete'
import AgendaStyleDraftPublish from '@/components/AgendaStyles/AgendaStyleDraftPublish'
import AgendaStyleDraftDelete from '@/components/AgendaStyles/AgendaStyleDraftDelete'
import AgendaStyle from '@/models/AgendaStyle'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'AgendaStyles',
  components: {
    Portal,
    AgendaStyleCreateUpdate,
    AgendaStyleEdit,
    AgendaStyleRegions,
    AgendaStylePageSettings,
    AgendaStyleVoteFormat,
    AgendaStyleMeetingVariables,
    AgendaStylePreviousVersions,
    AgendaStyleResume,
    AgendaStylePause,
    AgendaStyleActivate,
    AgendaStyleDelete,
    AgendaStyleDraftPublish,
    AgendaStyleDraftDelete
  },
  data: () => ({
    options: {},
    activeBoards: [],
    search: '',
    loading: false,
    modeType: '',
    builderType: 'agenda',
    modals: {
      createUpdate: false,
      edit: false,
      vote: false,
      variables: false,
      regions: false,
      pageSettings: false,
      previous: false,
      duplicate: false,
      resume: false,
      pause: false,
      activate: false,
      delete: false,
      draftPublish: false,
      draftDelete: false
    },
    breadcrumbsItems: [
      {
        text: 'Templates',
        disabled: false,
        href: ''
      },
      {
        text: 'Agenda Styles',
        disabled: true,
        href: ''
      }
    ],
    itemsMenu: [
      {
        icon: 'mdi-pencil',
        text: 'Edit',
        actions: 'createUpdate',
        optional: 'edit'
      },
      {
        icon: 'mdi-vote',
        text: 'Vote Format',
        actions: 'vote'
      },
      {
        icon: 'mdi-code-json',
        text: 'Meeting Variables',
        actions: 'variables'
      },
      {
        icon: 'mdi-history',
        text: 'Previous Versions',
        actions: 'previous'
      },
      {
        icon: 'mdi-content-copy',
        text: 'Duplicate',
        actions: 'createUpdate',
        optional: 'duplicate'
      },
      {
        icon: 'mdi-check-circle-outline',
        text: 'Activate',
        actions: 'activate'
      },
      {
        icon: 'mdi-play',
        text: 'Resume',
        actions: 'resume'
      },
      {
        icon: 'mdi-pause',
        text: 'Pause',
        type: 'important',
        actions: 'pause'
      },
      {
        icon: 'mdi-delete',
        text: 'Delete',
        type: 'important',
        actions: 'delete'
      }
    ],
    itemsDraft: [
      {
        icon: 'mdi-table-edit',
        text: 'Regions',
        actions: 'regions'
      },
      {
        icon: 'mdi-file-cog-outline',
        text: 'Page Settings',
        actions: 'pageSettings'
      },
      {
        icon: 'mdi-check-circle-outline',
        text: 'Publish',
        actions: 'draftPublish'
      },
      {
        icon: 'mdi-delete',
        text: 'Delete',
        type: 'important',
        actions: 'draftDelete'
      }
    ],
    headers: [
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Status',
        value: 'status',
        width: '100px',
        sortable: false,
        align: 'center'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '150px',
        align: 'center'
      }
    ],
    agendaStyle: {
      id: '',
      name: '',
      description: '',
      active: false,
      pending: false,
      paused: false,
      draft: false
    },
    agendaStyles: [{
      id: '',
      name: '',
      description: '',
      active: false,
      pending: false,
      paused: false,
      draft: false,
      activeTemplate: {},
      draftTemplate: {}
    }],
    agendaStyleTotalCount: 0,
    boardName: '',
    boardSelected: ''
  }),
  methods: {
    viewAgendaStyles () {
      console.log(this.agendaStyles)
    },
    refresh () {
      this.$forceUpdate()
    },
    openModal (item, optional, agendaStyle) {
      if (optional) this.modeType = optional
      this.modals[item] = true
      if (agendaStyle) this.agendaStyle = agendaStyle
    },
    async getDataFromApi () {
      this.loading = true
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage
      } = this.options

      let orderBy = null
      if (sortBy !== undefined && sortBy[0] !== undefined) {
        const sortDir = sortDesc[0] ? '-' : ''
        orderBy = sortDir + sortBy[0]
      }

      let agendaStyles = []
      const activeBoardIds = this.activeBoards.map(item => item.id)
      if (this.boardSelected) {
        agendaStyles = await AgendaStyle.page(page)
          .include(['board'])
          .where('board_id', this.boardSelected)
          .where('board.client_id', this.$store.state.client.activeClient.clientID)
          .orderBy(orderBy)
          .limit(itemsPerPage)
          .get()
        const agendaStylesResult = await AgendaStyle
          .include(['board'])
          .where('board_id', this.boardSelected)
          .where('board.client_id', this.$store.state.client.activeClient.clientID)
          .get()
        this.agendaStyleTotalCount = parseInt(agendaStylesResult.data.length)
      } else {
        agendaStyles = await AgendaStyle.page(page)
          .include(['board'])
          .whereIn('board_id', activeBoardIds)
          .where('board.client_id', this.$store.state.client.activeClient.clientID)
          .orderBy(orderBy)
          .limit(itemsPerPage)
          .get()
        const agendaStylesResult = await AgendaStyle
          .include(['board'])
          .whereIn('board_id', activeBoardIds)
          .where('board.client_id', this.$store.state.client.activeClient.clientID)
          .get()
        this.agendaStyleTotalCount = parseInt(agendaStylesResult.data.length)
      }
      this.agendaStyles = agendaStyles.data
      for (const elm of this.agendaStyles) {
        elm.active = false
        elm.pending = false
        elm.paused = false
        elm.reserved = false
        // elm[elm.status] = true

        const templatesRes = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/active-and-draft-template/' + elm.id)
        const { activeTemplate, draftTemplate } = templatesRes.data
        elm.activeTemplate = activeTemplate
        elm.draftTemplate = draftTemplate
        if (Object.keys(draftTemplate).length) {
          elm.draft = true
        }
        if (elm.status === 'inactive') {
          elm.paused = true
        }
        // console.log('templates', templatesRes)
      }
      this.loading = false
    },
    getActiveBoards () {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/boards-by-client-id/' + this.$store.state.client.activeClient.clientID)
        .then((res) => {
          this.activeBoards = res.data
        })
    },
    itemsMenuFiltered (item) {
      const itemsToRemove = []

      if (item.status === 'pending') {
        itemsToRemove.push('resume', 'pause')
      }

      if (item.status === 'active') {
        itemsToRemove.push('resume', 'activate')
      }

      if (item.status === 'inactive') {
        itemsToRemove.push('pause', 'activate')
      }

      return this.itemsMenu.filter(function (el) { return !itemsToRemove.includes(el.actions) })
    }
  },
  computed: {
    ...mapGetters('regionvariables', ['datetimeVariables', 'datetimeFormats', 'variableResolutions'])
  },
  mounted () {
    this.getActiveBoards()
    if (Object.keys(this.datetimeVariables).length === 0) {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-styles-template-builder/get-datetime-variables')
        .then((res) => {
          this.$store.dispatch('regionvariables/setDatetimeVariables', res.data)
        })
    }

    if (this.datetimeFormats.length === 0) {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-styles-template-builder/get-datetime-formats')
        .then((res) => {
          console.log('res datetimeformats', res)
          this.$store.dispatch('regionvariables/setDatetimeFormats', res.data)
        })
    }

    if (this.variableResolutions.length === 0) {
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-styles-template-builder/get-variable-resolutions')
        .then((res) => {
          console.log('res variable resolutions', res)
          this.$store.dispatch('regionvariables/setVariableResolutions', res.data)
        })
    }
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'client/setActiveClient') {
        if (state.activeClient !== null) {
          this.agendaStyles = []
          this.boardSelected = ''
          this.getActiveBoards()
          this.getDataFromApi()
        }
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    },
    boardSelected () {
      this.boardName = this.activeBoards.find(item => item.id === this.boardSelected)?.name ?? ''
      this.getDataFromApi()
    },
    activeBoards () {
      if (this.agendaStyles.length <= 1) {
        this.getDataFromApi()
      }
    }
  }
})

</script>

<style>
  .v-menu__content {
    overflow-y: unset !important;
    overflow-x: unset !important;
    contain: unset !important;
  }
</style>
