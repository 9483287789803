<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Vote Formats to use with <span class="font-weight-black">{{ agendaStyle.name }}</span> style</span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-row>
          <v-col class="d-flex flex-column align-content-space-between">

            <v-card
              elevation="0"
              height="200"
              class="d-flex align-center justify-center lightGrey--text text-uppercase"
              v-if="loading"
            >
              <v-row>
                <v-col
                  cols=12>
                  <v-skeleton-loader
                    class="mx-auto"
                    type="table-heading@3"
                    light
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </v-card>

            <v-container v-if="Object.keys(selectedVoteFormats).length === 0">
              <v-alert
                dense
                text
                class="text-left"
                type="warning"
                :icon=false
              >
                There are no Vote Formats defined for your organization.<br>
                You can create Vote Formats by going to Templates > Vote Formats.
              </v-alert>
            </v-container>
            <v-container v-else>
            <v-simple-table v-if="!loading">
              <template v-slot:default>
                <tbody>
                <tr
                  v-for="(item,n) in selectedVoteFormats"
                  :key="n"
                >
                  <td>
                    <v-switch
                      inset
                      v-model="item.selected"
                      :label="item.name"
                      color="secondary"
                      hide-details
                      class="py-4 ma-0"
                      :disabled="item.disabled"
                    ></v-switch>
                  </td>
                  <td>
                    <v-chip v-if="item.default">
                      Default
                    </v-chip>
                      <v-tooltip top v-if="item.disabled">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon size="20"
                              v-bind="attrs"
                              v-on="on">
                              mdi-pause
                            </v-icon>
                        </template>
                        <span>
                          Use of this Vote Format has been paused. While paused it cannot be selected or used in minutes.<br>
                          You can resume use of this Vote Format in Templates > Vote Formats.
                        </span>
                      </v-tooltip>
                  </td>
                  <!--
                  <td align="right" style="width: 15%">
                    <v-tooltip top v-if="item.active && !item.default">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="darkGrey"
                          size="18"
                          v-bind="attrs"
                          v-on="on"
                          @click="setAsDefault(n)"
                        >
                          <v-icon
                            size="20"
                            color="lightGrey"
                          >
                            mdi-star
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Set as default
                      </span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.default">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="darkGrey"
                          size="18"
                          v-bind="attrs"
                          v-on="on"
                          @click="setAsDefault(n)"
                        >
                          <v-icon
                            size="20"
                            color="orange lighten-2"
                          >
                            mdi-alert
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        Default Vote Formats can't be switched off
                      </span>
                    </v-tooltip>
                  </td>
                  -->
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            </v-container>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Close</v-btn>
        <v-btn depressed width="150" class="green white--text" @click="saveSettings()" v-if="Object.keys(selectedVoteFormats).length !== 0">
          <v-progress-circular v-if="saving===true" indeterminate size="16"></v-progress-circular>
          Save Selections
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'AgendaStyleVoteFormat',
  props: {
    agendaStyle: {},
    boardName: String,
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    saving: false,
    selectedVoteFormats: [],
    downloadedClientVoteFormats: [],
    downloadedStyleVoteFormats: []
  }),
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    setAsDefault (item) {
      for (let i = 0; i < this.voteFormat.length; i++) {
        this.voteFormat[i].default = false
      }
      this.voteFormat[item].default = true
    },
    getVoteFormats () {
      this.loading = true
      axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-styles/vote-formats/' + this.$store.state.client.activeClient.clientID + '/' + this.agendaStyle.id)
        .then((response) => {
          this.downloadedClientVoteFormats = response.data.client
          this.downloadedStyleVoteFormats = response.data.style
          this.addDataToFormats()
          this.loading = false
        }).catch(function (error) {
          console.log(error)
        })
    },
    addDataToFormats () {
      this.selectedVoteFormats = {}
      this.downloadedClientVoteFormats.forEach(format => {
        if (format.status === 'active') {
          format.active = true
        }

        // Set all to false initially. Will be set to true in later step if necessary.
        format.selected = false
        format.disabled = false
        format.default = false
        format.styleVoteFormatId = false

        if (format.status === 'inactive') {
          format.inactive = true
          format.disabled = true
        }

        this.selectedVoteFormats[format.id] = format
      })

      this.downloadedStyleVoteFormats.forEach(styleFormat => {
        this.selectedVoteFormats[styleFormat.format_id].selected = true
        this.selectedVoteFormats[styleFormat.format_id].default = styleFormat.default
        this.selectedVoteFormats[styleFormat.format_id].styleVoteFormatId = styleFormat.id
        // this.downloadedClientVoteFormats.default = format.default
      })

      console.log(this.selectedVoteFormats)
    },
    saveSettings () {
      this.saving = true
      // console.log(this.selectedVoteFormats)

      axios.post(process.env.VUE_APP_BACKEND_URL + '/api/agenda-styles/save-vote-formats/', { style_id: this.agendaStyle.id, formats: this.selectedVoteFormats })
        .then((response) => {
          this.show = false
        }).catch(function (error) {
          console.log(error)
        })
    }
  },
  watch: {
    show (val) {
      if (val) {
        this.getVoteFormats()
      } else {
        this.loading = false
        this.saving = false
      }
    }
  }
})

/*

It's mandatory to have at least ONE Vote Format selected. Need to block the switch when there is only one.

*/

</script>
