<template>

  <v-dialog
    width="700"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">
      <builder-in-progress-component :showBuilderInProgressModal="showBuilderInProgressModal"
                                     @closeModal="showBuilderInProgressModal = false"
                                     @clearRegion="clearData"/>

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Page Settings for <span class="font-weight-black">{{agendaStyle.name}}</span></span>
<!--        <v-btn primary @click="displayData">Display Data</v-btn>-->
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="closeModal"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-card-text>

        <v-tabs
          v-model="tab"
          centered
          icons-and-text
          class="custom-tab mb-6"
          color="secondary"
        >
          <v-tab key="0">
            <v-icon>mdi-list</v-icon>
            Font
          </v-tab>

          <v-tab key="1">
            <v-icon>mdi-list</v-icon>
            Numbering
          </v-tab>

          <v-tab key="2">
            <v-icon>mdi-list</v-icon>
            Margins
          </v-tab>

        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="0">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-select
                      label="Font Family"
                      outlined
                      small
                      hide-details
                      v-model="fontFamily"
                      :items="fontFamilies"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      label="Font Size"
                      outlined
                      small
                      hide-details
                      v-model="fontSize"
                      :items="fontSizes"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="1">
            <v-card flat>
              <v-card-text>
                <v-row class="pa-0 ma-0">
                  <v-col
                    cols="7"
                    class="pa-0 ma-0 pr-8"
                  >
                    <v-row>
                      <v-col>
                        <v-select
                          label="Region Numbering"
                          outlined
                          small
                          hide-details
                          v-model="settingNumberRegion"
                          :items="numberingFilter"
                          item-value="value"
                          item-text="type"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          label="Great Numbering"
                          outlined
                          hide-details
                          v-model="settingNumberGreat"
                          :items="numberingType"
                          item-value="value"
                          item-text="type"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          label="Grand Numbering"
                          outlined
                          hide-details
                          v-model="settingNumberGrand"
                          :items="numberingType"
                          item-value="value"
                          item-text="type"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          label="Parent Numbering"
                          outlined
                          hide-details
                          v-model="settingNumberParent"
                          :items="numberingType"
                          item-value="value"
                          item-text="type"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          label="First Numbering"
                          outlined
                          hide-details
                          v-model="settingNumberFirst"
                          :items="numberingType"
                          item-value="value"
                          item-text="type"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="5"
                    class="grey lighten-3 mt-3 mb-3 pa-8"
                  >
                    <span class="d-flex mb-3" v-if="symbolExistsFromType('number_region')">
                      <span v-text="getSymbolFromType('number_region')" class="mr-2"></span>Region
                    </span>
                    <span class="d-flex mb-3 ml-2" v-if="symbolExistsFromType('number_great')">
                      <span v-text="getSymbolFromType('number_great')" class="mr-2"></span> Great
                    </span>
                    <span class="d-flex mb-3 ml-4" v-if="symbolExistsFromType('number_grand')">
                      <span v-text="getSymbolFromType('number_grand')" class="mr-2"></span> Grant
                    </span>
                    <span class="d-flex mb-3 ml-6" v-if="symbolExistsFromType('number_parent')">
                      <span v-text="getSymbolFromType('number_parent')" class="mr-2"></span> Parent
                    </span>
                    <span class="d-flex mb-3 ml-8" v-if="symbolExistsFromType('number_first')">
                      <span v-text="getSymbolFromType('number_first')" class="mr-2"></span> First
                    </span>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item key="2">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card
                      elevation="3"
                      style="width: 2.83in; height: 3.66in;"
                    >
                      <v-card-text class="pa-0" style="padding: 0.166in !important; position: relative; display: block; width: 100%; height: 100%;">

                        <span
                          style="border: 1px dashed rgba(0,0,0,0.3); position: absolute;"
                          :style="updateMargins"
                        >
                            Content
                        </span>

                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col class="pl-12">
                    <v-row>
                      <v-col class="d-flex mb-4 text-uppercase font-weight-medium">
                        Letter Paper (8.5" x 11")
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Top"
                          hide-details
                          readonly
                          v-model="topMargin"
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('top')"
                          :disabled="topMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('top')"
                          :disabled="topMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Right"
                          hide-details
                          readonly
                          v-model="rightMargin"
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('right')"
                          :disabled="rightMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('right')"
                          :disabled="rightMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Bottom"
                          type="number"
                          hide-details
                          readonly
                          v-model="bottomMargin"
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('bottom')"
                          :disabled="bottomMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('bottom')"
                          :disabled="bottomMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex">
                        <v-text-field
                          outlined
                          label="Left"
                          type="number"
                          hide-details
                          readonly
                          v-model="leftMargin"
                          suffix="in"
                          dense
                          class="mr-6"
                        ></v-text-field>
                        <v-btn
                          depressed
                          icon
                          @click="decrementMargin('left')"
                          :disabled="leftMargin<=marginMin"
                        >
                          <v-icon size="18">
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          icon
                          @click="incrementMargin('left')"
                          :disabled="leftMargin>=marginMax"
                        >
                          <v-icon size="18">
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="saveSettings">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import AgendaStyleTemplateBuilder from '@/models/AgendaStyleTemplateBuilder'
import BuilderInProgressComponent from '@/components/AgendaStyles/Regions/BuilderInProgressComponent'
import { mapState } from 'vuex'
import axios from 'axios'

function initialState () {
  return {
    draft: Boolean,
    version_date: '',
    regions: {},
    settings: {
      margins: {
        top: '',
        right: '',
        bottom: '',
        left: ''
      },
      numbering: {
        number_region: '',
        number_great: '',
        number_grand: '',
        number_parent: '',
        number_first: ''
      },
      font: {
        font_family: '',
        font_size: ''
      }
    },
    showBuilderInProgressModal: false,
    tab: 0,
    marginMax: 2.500,
    marginMin: 0.500,
    marginInterval: 0.25,
    margins: {
      top: 0.500,
      right: 0.500,
      bottom: 0.500,
      left: 0.500
    },
    numberingType: [
      { value: 'decimal', type: 'Decimal', sample: '1.' },
      { value: 'alpha_cap', type: 'Capital Alphabet', sample: 'A.' },
      { value: 'alpha_low', type: 'Lowercase Alphabet', sample: 'a.' },
      { value: 'roman_cap', type: 'Capital Roman', sample: 'I.' },
      { value: 'roman_low', type: 'Lowercase Roman', sample: 'i.' },
      { value: 'circle', type: 'Circle', sample: '●' },
      { value: 'square', type: 'Square', sample: '■' },
      { value: 'disc', type: 'Disc', sample: '○' },
      { value: 'diamond', type: 'Diamond', sample: '◆' },
      { value: '', type: 'Same as previous region', sample: 'previous', exclude: true }
    ],
    numberingSelected: [
      { selected: '1.', symbol: '1.' },
      { selected: 'A.', symbol: 'A.' },
      { selected: 'a.', symbol: 'a.' },
      { selected: 'I.', symbol: 'I.' },
      { selected: '●', symbol: '●' }
    ],
    fontFamilies: [
      'Algerian',
      'Arial',
      'Calibri',
      'Cambria',
      'Courier',
      'Franklin Gothic',
      'Futura',
      'Helvetica',
      'Rockwell',
      'Times New Roman',
      'Verdana'
    ],
    // fontFamily: null,
    fontSizes: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]
    // fontSize: null
  }
}

export default Vue.extend({
  name: 'AgendaStylePageSettings',
  props: {
    agendaStyle: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  components: {
    BuilderInProgressComponent
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapState('region', ['contentHasBeenModified']),
    contentHasBeenModified: {
      get () {
        return this.$store.state.region.contentHasBeenModified
      },
      set (value) {
        this.$store.dispatch('region/setContentHasBeenModified', value)
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    fontFamily: {
      get () {
        return this.settings?.font?.font_family ?? ''
      },
      set (value) {
        this.settings.font.font_family = value
      }
    },
    fontSize: {
      get () {
        return this.settings?.font?.font_size ?? ''
      },
      set (value) {
        this.settings.font.font_size = value
      }
    },
    settingNumberRegion: {
      get () {
        return this.settings?.numbering?.number_region
      },
      set (val) {
        this.$set(this.settings.numbering, 'number_region', val)
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberGreat: {
      get () {
        return this.settings?.numbering?.number_great
      },
      set (val) {
        this.$set(this.settings.numbering, 'number_great', val)
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberGrand: {
      get () {
        return this.settings?.numbering?.number_grand
      },
      set (val) {
        this.$set(this.settings.numbering, 'number_grand', val)
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberParent: {
      get () {
        return this.settings?.numbering?.number_parent
      },
      set (val) {
        this.$set(this.settings.numbering, 'number_parent', val)
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberFirst: {
      get () {
        return this.settings?.numbering?.number_first
      },
      set (val) {
        this.$set(this.settings.numbering, 'number_first', val)
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    topMargin: {
      get () {
        return this.settings?.margins?.top ?? 0.50
      },
      set (value) {
        this.$set(this.settings.margins, 'top', parseFloat(value))
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    leftMargin: {
      get () {
        return this.settings?.margins?.left ?? 0.50
      },
      set (value) {
        this.$set(this.settings.margins, 'left', parseFloat(value))
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    rightMargin: {
      get () {
        return this.settings?.margins?.right ?? 0.50
      },
      set (value) {
        this.$set(this.settings.margins, 'right', parseFloat(value))
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    bottomMargin: {
      get () {
        return this.settings?.margins?.bottom ?? 0.50
      },
      set (value) {
        this.$set(this.settings.margins, 'bottom', parseFloat(value))
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    numberingFilter () {
      return this.numberingType.filter(item => item.exclude === undefined)
    },
    updateMargins () {
      return {
        width: 'calc(100% - ' + (parseFloat(this.leftMargin) + parseFloat(this.rightMargin)) / 3 + 'in)',
        height: 'calc(100% - ' + (parseFloat(this.topMargin) + parseFloat(this.bottomMargin)) / 3 + 'in)',
        top: this.topMargin / 3 + 'in',
        left: this.leftMargin / 3 + 'in'
      }
    }
  },
  watch: {
    numberingSelected: {
      handler () {
        for (let i = 0; i < 5; i++) {
          if (this.numberingSelected[i].selected === 'previous') {
            this.numberingSelected[i].symbol = this.numberingSelected[i - 1].symbol
          } else {
            this.numberingSelected[i].symbol = this.numberingSelected[i].selected
          }
        }
      },
      deep: true
    },
    settingNumberRegion: {
      handler () {
        console.log('changed watch')
        if (this.settings?.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberGreat: {
      handler () {
        if (this.settings?.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberGrand: {
      handler () {
        if (this.settings?.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberParent: {
      handler () {
        if (this.settings?.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberFirst: {
      handler () {
        if (this.settings?.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    show (value) {
      if (value) {
        this.getTemplate()
      }
    }
  },
  methods: {
    // displayData () {
    //   console.log(this.$data)
    // },
    incrementMargin (item) {
      this[item + 'Margin'] = parseFloat(this[item + 'Margin'] + this.marginInterval).toFixed(3)
    },
    decrementMargin (item) {
      this[item + 'Margin'] = parseFloat(this[item + 'Margin'] - this.marginInterval).toFixed(3)
    },
    symbolExistsFromType (type) {
      if (this.settings.numbering && this.settings.numbering[type]) {
        return this.settings.numbering[type].length > 0
      }
      return false
    },
    getSymbolFromType (type) {
      const value = this.settings.numbering[type]
      if (!value) {
        return ''
      }
      const obj = this.numberingType.find(o => o.value === value)
      return obj.sample
    },
    numberSelectedHandle () {
      for (let i = 0; i < 5; i++) {
        if (this.numberingSelected[i].selected === 'previous') {
          this.numberingSelected[i].symbol = this.numberingSelected[i - 1].symbol
        } else {
          this.numberingSelected[i].symbol = this.numberingSelected[i].selected
        }
      }
    },
    clearData () {
      console.log('Clear Region')
      this.showBuilderInProgressModal = false
      Object.assign(this.$data, initialState())
      this.show = false
      this.$store.dispatch('region/RESET_MODULE')
    },
    closeModal () {
      if (this.contentHasBeenModified) {
        this.showBuilderInProgressModal = true
      } else {
        this.clearData()
      }
    },
    getTemplate () {
      // if (this.settings.length > 0) return false
      AgendaStyleTemplateBuilder.find(this.agendaStyle.id).then(response => {
        let template = {}
        if (this.agendaStyle.draft) {
          template = response.draftTemplate
        } else {
          template = response.activeTemplate
        }
        const { draft, version_date, settings, regions } = template
        this.draft = draft
        this.settings = settings
        this.regions = regions
        this.version_date = version_date
      }).catch(error => {
        console.error('AgendaStyle PageSettings Error:', error.message)
      })
    },
    saveSettings () {
      const data = {
        version_date: this.version_date,
        regions: this.regions,
        settings: this.settings,
        draft: this.draft
      }
      const axiosUrl = '/api/agenda-style-template-builder/' + this.agendaStyle.id
      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data
      }).then(response => {
        this.contentHasBeenModified = false
      }).catch(error => {
        console.error(error.message)
      })
    }
  }
})
</script>
