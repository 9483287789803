<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Previous Versions of <span class="font-weight-black">{{agendaStyle.name}}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-text>
        <v-card-text class="white text--primary text-center" v-if="updatedVersions.length === 0 && !createdVersion && !loading">
          No Versions Have Been Created Yet
        </v-card-text>
        <v-timeline align-top v-else>
          <v-timeline-item
            v-for="(version, versionKey) in updatedVersions"
            :key="versionKey"
            fill-dot
            color="secondary"
            icon="mdi-update"
          >
            <v-card>
              <v-card-text class="white text--primary">
                Updated version on {{ version.date }} by {{ version.user_name }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-if="createdVersion"
            fill-dot
            color="primary"
            icon="mdi-file-outline"
          >
            <v-card>
              <v-card-text class="white text--primary">
                Created on on {{ createdVersion.date }} by {{ createdVersion.user_name }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'AgendaStylePreviousVersions',
  props: {
    agendaStyle: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    versions: []
  }),
  computed: {
    updatedVersions () {
      return [...this.versions].slice(0, -1)
    },
    createdVersion () {
      return [...this.versions].pop()
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.loading = true
        axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/get-published-versions/' + this.agendaStyle.id)
          .then((res) => {
            this.versions = res.data
            this.loading = false
          })
      }
    }
  }
})
</script>
