var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',_vm._g(_vm._b({staticStyle:{"position":"relative"}},'span',_vm.attrs,false),_vm.on),[(_vm.type === undefined && _vm.isEmptyRegion())?_c('span',[_c('span',{staticClass:"d-flex row-editor"},[_c('v-btn',{attrs:{"tile":"","block":"","height":"100%"},on:{"click":function($event){return _vm.goToRegion(_vm.region)}}},[_c('v-icon',[_vm._v("mdi-vertical-dots")]),_vm._v(" Empty Region "),(_vm.region.name)?_c('span',{staticClass:"pl-3"},[_vm._v("("+_vm._s(_vm.region.name)+")")]):_vm._e()],1)],1),_c('span',{staticClass:"edit-column"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","min-width":"30","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","tile":"","block":"","height":"100%"},on:{"click":function($event){return _vm.goToRegion(_vm.region)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,false,1759494726)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"text-uppercase text-caption"},[_vm._v(" Empty Region "),(_vm.region.name)?_c('span',[_vm._v(" - "+_vm._s(_vm.region.name))]):_vm._e()]),_c('v-list-item',{staticClass:"d-flex justify-start align-start"},[(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionBefore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-up")])],1)]}}],null,false,4150792255)},[_c('span',[_vm._v(" Insert region before ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToRegion(_vm.region)},"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,false,3214105392)},[_c('span',[_vm._v(" Edit "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.duplicateRegion,"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,2214600566)},[_c('span',[_vm._v(" Duplicate "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=true}},model:{value:(_vm.addRegion),callback:function ($$v) {_vm.addRegion=$$v},expression:"addRegion"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,false,3877248931)},[_c('span',[_vm._v(" Insert another region below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('up')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,false,2794744361)},[_c('span',[_vm._v(" Move region up ")])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('down')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,false,3352050441)},[_c('span',[_vm._v(" Move region down ")])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-page-break")])],1)]}}],null,false,1924473711)},[_c('span',[_vm._v(" Insert page break below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)]}}],null,false,2519789807)},[_c('span',[_vm._v(" Restore "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" to original ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-speed-dial',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v(" mdi-delete ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"grey"}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"important"},on:{"click":_vm.removeRegion}},[_vm._v(" YES ")])],1)]}}],null,false,1062227803)},[_c('span',[_vm._v(" Delete "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionAfter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-down")])],1)]}}],null,false,2068271829)},[_c('span',[_vm._v(" Insert region after ")])]):_vm._e()],1),(_vm.addRegionMenu)?_c('v-list-item',[_c('v-card',{staticStyle:{"overflow-y":"auto"},attrs:{"elevation":"0","max-height":"300","width":"100%"}},[_c('v-list',_vm._l((_vm.testRegions),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1):_vm._e(),(_vm.addRegionMenu)?_c('v-list-item',{staticClass:"pa-7 py-0 pb-4",on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-border-none-variant")]),_vm._v(" Blank region ")],1)],1):_vm._e()],1)],1)],1)]):_vm._e(),(_vm.type === undefined)?_c('span',_vm._l((this.region.begin_block.rows),function(row,index){return _c('span',{key:index},[_c('span',{staticClass:"d-flex row-editor"},[_c('agenda-style-region-column',{attrs:{"columns":row.columns}})],1),_c('span',{staticClass:"edit-column"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","min-width":"30","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","tile":"","block":"","height":"100%"},on:{"click":function($event){return _vm.goToRegion(_vm.region)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"text-uppercase text-caption"},[_vm._v(" "+_vm._s(_vm.region.name)+" ")]),_c('v-list-item',{staticClass:"d-flex justify-start align-start"},[(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionBefore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region before ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToRegion(_vm.region)},"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.duplicateRegion,"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" Duplicate "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=true}},model:{value:(_vm.addRegion),callback:function ($$v) {_vm.addRegion=$$v},expression:"addRegion"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert another region below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('up')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region up ")])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('down')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region down ")])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-page-break")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert page break below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)]}}],null,true)},[_c('span',[_vm._v(" Restore "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" to original ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-speed-dial',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v(" mdi-delete ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"grey"}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"important"},on:{"click":_vm.removeRegion}},[_vm._v(" YES ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionAfter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region after ")])]):_vm._e()],1),(_vm.addRegionMenu)?_c('v-list-item',[_c('v-card',{staticStyle:{"overflow-y":"auto"},attrs:{"elevation":"0","max-height":"300","width":"100%"}},[_c('v-list',_vm._l((_vm.testRegions),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1):_vm._e(),(_vm.addRegionMenu)?_c('v-list-item',{staticClass:"pa-7 py-0 pb-4",on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-border-none-variant")]),_vm._v(" Blank region ")],1)],1):_vm._e()],1)],1)],1)])}),0):_vm._e(),(_vm.type === undefined)?_c('span',_vm._l((this.region.item_container.rows),function(row,index){return _c('span',{key:index},[_c('span',{staticClass:"d-flex row-editor"},[_c('agenda-style-region-column',{attrs:{"columns":row.columns}})],1),_c('span',{staticClass:"edit-column"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","min-width":"30","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","tile":"","block":"","height":"100%"},on:{"click":function($event){return _vm.goToRegion(_vm.region)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"text-uppercase text-caption"},[_vm._v(" "+_vm._s(_vm.region.name)+" ")]),_c('v-list-item',{staticClass:"d-flex justify-start align-start"},[(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionBefore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region before ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToRegion(_vm.region)},"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.duplicateRegion,"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" Duplicate "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=true}},model:{value:(_vm.addRegion),callback:function ($$v) {_vm.addRegion=$$v},expression:"addRegion"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert another region below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('up')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region up ")])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('down')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region down ")])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-page-break")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert page break below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)]}}],null,true)},[_c('span',[_vm._v(" Restore "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" to original ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-speed-dial',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v(" mdi-delete ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"grey"}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"important"},on:{"click":_vm.removeRegion}},[_vm._v(" YES ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionAfter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region after ")])]):_vm._e()],1),(_vm.addRegionMenu)?_c('v-list-item',[_c('v-card',{staticStyle:{"overflow-y":"auto"},attrs:{"elevation":"0","max-height":"300","width":"100%"}},[_c('v-list',_vm._l((_vm.testRegions),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1):_vm._e(),(_vm.addRegionMenu)?_c('v-list-item',{staticClass:"pa-7 py-0 pb-4",on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-border-none-variant")]),_vm._v(" Blank region ")],1)],1):_vm._e()],1)],1)],1)])}),0):_vm._e(),(_vm.type === undefined)?_c('span',_vm._l((this.region.end_block.rows),function(row,index){return _c('span',{key:index},[_c('span',{staticClass:"d-flex row-editor"},[_c('agenda-style-region-column',{attrs:{"columns":row.columns}})],1),_c('span',{staticClass:"edit-column"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","min-width":"30","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","tile":"","block":"","height":"100%"},on:{"click":function($event){return _vm.goToRegion(_vm.region)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"text-uppercase text-caption"},[_vm._v(" "+_vm._s(_vm.region.name)+" ")]),_c('v-list-item',{staticClass:"d-flex justify-start align-start"},[(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionBefore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region before ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToRegion(_vm.region)},"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.duplicateRegion,"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" Duplicate "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=true}},model:{value:(_vm.addRegion),callback:function ($$v) {_vm.addRegion=$$v},expression:"addRegion"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert another region below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('up')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region up ")])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('down')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region down ")])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-page-break")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert page break below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)]}}],null,true)},[_c('span',[_vm._v(" Restore "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" to original ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-speed-dial',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v(" mdi-delete ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"grey"}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"important"},on:{"click":_vm.removeRegion}},[_vm._v(" YES ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionAfter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region after ")])]):_vm._e()],1),(_vm.addRegionMenu)?_c('v-list-item',[_c('v-card',{staticStyle:{"overflow-y":"auto"},attrs:{"elevation":"0","max-height":"300","width":"100%"}},[_c('v-list',_vm._l((_vm.testRegions),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1):_vm._e(),(_vm.addRegionMenu)?_c('v-list-item',{staticClass:"pa-7 py-0 pb-4",on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-border-none-variant")]),_vm._v(" Blank region ")],1)],1):_vm._e()],1)],1)],1)])}),0):_vm._e(),(_vm.type === undefined)?_c('span',_vm._l((this.region.no_item_text.rows),function(row,index){return _c('span',{key:index},[_c('span',{staticClass:"d-flex row-editor"},[_c('agenda-style-region-column',{attrs:{"columns":row.columns}})],1),_c('span',{staticClass:"edit-column"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","min-width":"30","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","tile":"","block":"","height":"100%"},on:{"click":function($event){return _vm.goToRegion(_vm.region)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"text-uppercase text-caption"},[_vm._v(" "+_vm._s(_vm.region.name)+" ")]),_c('v-list-item',{staticClass:"d-flex justify-start align-start"},[(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionBefore}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region before ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToRegion(_vm.region)},"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.duplicateRegion,"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" Duplicate "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=true}},model:{value:(_vm.addRegion),callback:function ($$v) {_vm.addRegion=$$v},expression:"addRegion"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert another region below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('up')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region up ")])]):_vm._e(),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRegion('down')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move region down ")])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-page-break")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert page break below "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))])])]):_vm._e(),(_vm.builderType==='meeting')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-rotate-left")])],1)]}}],null,true)},[_c('span',[_vm._v(" Restore "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" to original ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-speed-dial',{scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"mouseenter":function($event){_vm.addRegionMenu=false}},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v(" mdi-delete ")])],1)]},proxy:true}],null,true),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"grey"}},[_vm._v(" NO ")]),_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"important"},on:{"click":_vm.removeRegion}},[_vm._v(" YES ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete "),_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" region ")])]),(_vm.builderType==='agenda')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.insertRegionAfter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-arrow-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert region after ")])]):_vm._e()],1),(_vm.addRegionMenu)?_c('v-list-item',[_c('v-card',{staticStyle:{"overflow-y":"auto"},attrs:{"elevation":"0","max-height":"300","width":"100%"}},[_c('v-list',_vm._l((_vm.testRegions),function(item,i){return _c('v-list-item',{key:i,on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1):_vm._e(),(_vm.addRegionMenu)?_c('v-list-item',{staticClass:"pa-7 py-0 pb-4",on:{"click":_vm.testAddRegion}},[_c('v-list-item-title',{staticClass:"grey--text"},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-border-none-variant")]),_vm._v(" Blank region ")],1)],1):_vm._e()],1)],1)],1)])}),0):_vm._e(),(_vm.type !== undefined)?_c('span',_vm._l((_vm.regionType(this.type)),function(row,index){return _c('span',{key:index},[_c('span',{staticClass:"d-flex row-editor"},[_c('div',{staticClass:"d-flex",staticStyle:{"position":"relative"}},[_c('agenda-style-region-column',{attrs:{"columns":row.columns}}),_c('span',{staticClass:"edit-column"},[_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","min-width":"30"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"transparent","tile":"","block":""},on:{"click":function($event){return _vm.goToRow(row, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-vertical-dots")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.insertRowBelow(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-before")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert row before ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.goToRow(row, index)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(" Edit row ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.duplicateRow(index)},"mouseenter":function($event){_vm.addRegionMenu=false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" Duplicate Row ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRow(index, 'up')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-up")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move row up ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.moveRow(index, 'down')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('span',[_vm._v(" Move row down ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.removeRow(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"important"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(" Delete row ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.insertRowAfter(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-table-row-plus-after")])],1)]}}],null,true)},[_c('span',[_vm._v(" Insert row after ")])])],1)],1)],1)],1)],1)])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }